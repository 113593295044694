export default function initAnimation() {
  (function (s, i, u, o, c, w, d, t, n, x, e, p, a, b) {
    (a = Array.from(d.querySelectorAll('svg#' + i.root)).filter(n => !n.svgatorPlayer)[0] || {}).svgatorPlayer = {
      ready: (function (a) {
        b = [];
        return function (c) {
          return c ? (b.push(c), a.svgatorPlayer) : b
        }
      })(a)
    };
    w[o] = w[o] || {};
    w[o][s] = w[o][s] || [];
    w[o][s].push(i);
    e = d.createElementNS(n, t);
    e.async = true;
    e.setAttributeNS(x, 'href', [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''));
    e.setAttributeNS(null, 'src', [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''));
    p = d.getElementsByTagName(t)[0];
    p.parentNode.insertBefore(e, p);
  })('91c80d77', {
    "root": "eHpquLvdOY51", "version": "2022-05-04", "animations": [{
      "elements": {
        "eHpquLvdOY515": {
          "transform": {
            "data": {"t": {"x": 137.75, "y": 195.27}},
            "keys": {
              "o": [{"t": 0, "v": {"x": 0, "y": -3.742942, "type": "corner"}, "e": [0.42, 0, 1, 1]}, {
                "t": 1000,
                "v": {"x": 0, "y": -7.485884, "type": "corner"},
                "e": [0.42, 0, 1, 1]
              }, {"t": 2000, "v": {"x": 0, "y": 0, "type": "corner"}}, {
                "t": 3000,
                "v": {"x": 0, "y": -3.742942, "type": "corner"},
                "e": [0, 0, 0.58, 1]
              }]
            }
          }
        },
        "eHpquLvdOY517": {
          "transform": {
            "data": {
              "o": {"x": 201.199169, "y": 196.510493, "type": "corner"},
              "t": {"x": -201.199169, "y": -196.510493}
            },
            "keys": {
              "r": [{"t": 0, "v": 0}, {"t": 1000, "v": 3.018715, "e": [0.42, 0, 1, 1]}, {
                "t": 2000,
                "v": -1.697742,
                "e": [0.42, 0, 0.58, 1]
              }, {"t": 3000, "v": 0}]
            }
          }
        },
        "eHpquLvdOY523": {
          "transform": {
            "data": {
              "s": {"x": 4.772894, "y": 6.290397},
              "t": {"x": -1.558206, "y": -0.609732}
            },
            "keys": {
              "o": [{"t": 500, "v": {"x": 168.244504, "y": 178.595114, "type": "corner"}}, {
                "t": 1000,
                "v": {"x": 168.585857, "y": 185.068087, "type": "corner"},
                "e": [0.42, 0, 1, 1]
              }, {"t": 1500, "v": {"x": 168.385805, "y": 177.584875, "type": "corner"}}]
            }
          }
        },
        "eHpquLvdOY525": {
          "transform": {
            "data": {
              "s": {"x": 4.772894, "y": 6.290397},
              "t": {"x": -1.558206, "y": -0.609732}
            },
            "keys": {
              "o": [{"t": 500, "v": {"x": 230.881506, "y": 178.421625, "type": "corner"}}, {
                "t": 1000,
                "v": {"x": 231.222859, "y": 184.894598, "type": "corner"},
                "e": [0.42, 0, 1, 1]
              }, {"t": 1500, "v": {"x": 231.022807, "y": 177.411386, "type": "corner"}}]
            }
          }
        },
        "eHpquLvdOY533": {
          "transform": {
            "keys": {
              "o": [{"t": 0, "v": {"x": 0, "y": 0, "type": "corner"}}, {
                "t": 500,
                "v": {"x": 0, "y": 0, "type": "corner"}
              }, {"t": 1000, "v": {"x": 0.197078, "y": 1.864978, "type": "corner"}}, {
                "t": 1500,
                "v": {"x": 0.147065, "y": -0.005825, "type": "corner"}
              }, {"t": 2000, "v": {"x": 0.091619, "y": 1.864825, "type": "corner"}}, {
                "t": 2500,
                "v": {"x": 0.063893, "y": 3.736091, "type": "corner"}
              }, {"t": 3000, "v": {"x": 0, "y": 0, "type": "corner"}}]
            }
          }
        },
        "eHpquLvdOY548": {
          "points": [{
            "t": 0,
            "v": [121.23, 228.99, 195.95, 259.27, 225.9, 182.49, 290, 202.58],
            "e": [0.42, 0, 1, 1]
          }, {
            "t": 1000,
            "v": [107.038251, 260.183539, 195.95, 259.27, 225.9, 182.49, 290, 202.58],
            "e": [0.42, 0, 1, 1]
          }, {
            "t": 1500,
            "v": [107.038251, 260.183539, 173.952471, 213.179651, 225.9, 182.49, 290, 202.58],
            "e": [0.42, 0, 1, 1]
          }, {
            "t": 2000,
            "v": [107.038251, 260.183539, 193.766845, 259.577048, 225.9, 182.49, 290, 202.58],
            "e": [0.42, 0, 1, 1]
          }, {
            "t": 2500,
            "v": [107.038251, 260.183539, 193.766845, 259.577048, 225.9, 182.49, 301.284349, 248.053511],
            "e": [0.42, 0, 1, 1]
          }, {"t": 3000, "v": [120.356116, 229.858452, 193.766845, 259.577048, 225.9, 182.49, 290.240271, 204.385385]}]
        },
        "eHpquLvdOY549": {
          "transform": {
            "data": {"t": {"x": 121.23, "y": 228.99}},
            "keys": {
              "o": [{"t": 0, "v": {"x": 0, "y": 0, "type": "corner"}, "e": [0.42, 0, 1, 1]}, {
                "t": 1100,
                "v": {"x": -14.292358, "y": 30.02184, "type": "corner"},
                "e": [0.42, 0, 1, 1]
              }, {"t": 2500, "v": {"x": -14.292358, "y": 30.02184, "type": "corner"}, "e": [0.42, 0, 1, 1]}, {
                "t": 3000,
                "v": {"x": 0, "y": 0, "type": "corner"}
              }]
            }
          }
        },
        "eHpquLvdOY550": {
          "transform": {
            "data": {"t": {"x": 195.95, "y": 259.27}},
            "keys": {
              "o": [{"t": 1000, "v": {"x": -2.27378, "y": -1.819517, "type": "corner"}}, {
                "t": 1500,
                "v": {"x": -24.686786, "y": -43.971382, "type": "corner"},
                "e": [0.42, 0, 1, 1]
              }, {"t": 2000, "v": {"x": -2.27378, "y": -1.819517, "type": "corner"}}]
            }
          }
        },
        "eHpquLvdOY552": {
          "transform": {
            "data": {"t": {"x": 290, "y": 202.58}},
            "keys": {
              "o": [{"t": 2000, "v": {"x": 0, "y": 0, "type": "corner"}}, {
                "t": 2500,
                "v": {"x": 11.368911, "y": 44.577883, "type": "corner"}
              }, {"t": 3000, "v": {"x": 0, "y": 0, "type": "corner"}}]
            }
          }
        },
        "eHpquLvdOY555": {
          "transform": {
            "data": {"s": {"x": 0.238482, "y": 0.238478}, "t": {"x": -183.194553, "y": -186.585178}}, "keys": {
              "o": [{
                "t": 0,
                "v": {"x": 171.740812, "y": 150.625069, "type": "cusp", "end": {"x": 171.740812, "y": 150.625069}},
                "e": [0.42, 0, 1, 1]
              }, {
                "t": 500,
                "v": {
                  "x": 219.894092,
                  "y": 146.440109,
                  "type": "cusp",
                  "start": {"x": 203.693563, "y": 147.431978},
                  "end": {"x": 236.09462, "y": 145.44824}
                },
                "e": [0, 0, 0.58, 1]
              }, {
                "t": 1000,
                "v": {
                  "x": 257.315258,
                  "y": 155.915037,
                  "type": "cusp",
                  "start": {"x": 246.081923, "y": 165.005022},
                  "end": {"x": 268.548592, "y": 146.825053}
                },
                "e": [0.42, 0, 1, 1]
              }, {
                "t": 1500,
                "v": {
                  "x": 264.315258,
                  "y": 104.999092,
                  "type": "cusp",
                  "start": {"x": 270.117573, "y": 117.710964},
                  "end": {"x": 258.690679, "y": 92.676609}
                },
                "e": [0, 0, 0.58, 1]
              }, {
                "t": 2000,
                "v": {
                  "x": 224.192192,
                  "y": 82.629866,
                  "type": "cusp",
                  "start": {"x": 237.88897, "y": 84.153063},
                  "end": {"x": 208.296951, "y": 80.862182}
                },
                "e": [0.42, 0, 1, 1]
              }, {
                "t": 2500,
                "v": {
                  "x": 172.945624,
                  "y": 96.846656,
                  "type": "cusp",
                  "start": {"x": 185.178675, "y": 78.001144},
                  "end": {"x": 164.159183, "y": 110.382525}
                },
                "e": [0, 0, 0.58, 1]
              }, {
                "t": 3000,
                "v": {"x": 171.740812, "y": 150.625069, "type": "cusp", "start": {"x": 171.740812, "y": 150.625069}},
                "e": [0.42, 0, 1, 1]
              }]
            }
          }
        }
      },
      "s": "MDRA1ZDk5NDA4MMjkzOTA3ZjkyGODc4ZDhjNDAU1ODUxNGU0ZTXRlNGE0MDgyVUzg3OTA4MzgxTOTI4NzhkOGMP0MEw1ODRmNGFE0MDg3OTI4MBzkwN2Y5Mjg3AOGQ4YzkxNDBRNNTg0ZTRhNDQA4NDg3OGE4YATQwNTg0ZjRhTNDA3ZjhhOTIV4MzkwOGM3ZjKkyODM0MDU4OVDRWN2ZOOGE5IMTgzNGE0MDkTxOGU4M0U4MzWgySDQwNTg0ZJjRhNDA4NDhlLOTE0MEc1ODRSmNGU0ZTli"
    }], "options": "MDCAxMDgyMjlJNL2E3YjY4Nzk3IYkoyOUU0MTIS5NzNONzY2ODYZiMjk4NA|"
  }, 'https://cdn.svgator.com/ply/', '__SVGATOR_PLAYER__', '2024-09-05', window, document, 'script', 'http://www.w3.org/2000/svg', 'http://www.w3.org/1999/xlink')
}
