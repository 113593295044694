export default function initAnimation() {
  (function (s, i, u, o, c, w, d, t, n, x, e, p, a, b) {
    (a = Array.from(d.querySelectorAll('svg#' + i.root)).filter(n => !n.svgatorPlayer)[0] || {}).svgatorPlayer = {
      ready: (function (a) {
        b = [];
        return function (c) {
          return c ? (b.push(c), a.svgatorPlayer) : b
        }
      })(a)
    };
    w[o] = w[o] || {};
    w[o][s] = w[o][s] || [];
    w[o][s].push(i);
    e = d.createElementNS(n, t);
    e.async = true;
    e.setAttributeNS(x, 'href', [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''));
    e.setAttributeNS(null, 'src', [u, s, '.', 'j', 's', '?', 'v', '=', c].join(''));
    p = d.getElementsByTagName(t)[0];
    p.parentNode.insertBefore(e, p);
  })('91c80d77', {
    "root": "e8NZwtkV3qQ1", "version": "2022-05-04", "animations": [{
      "elements": {
        "e8NZwtkV3qQ5": {
          "transform": {
            "data": {
              "o": {"x": 94.797483, "y": 127.070478, "type": "corner"},
              "t": {"x": -94.797482, "y": -127.070478}
            },
            "keys": {
              "r": [{"t": 0, "v": 0, "e": [0.47, 0, 0.745, 0.715]}, {
                "t": 4000,
                "v": 16.519591,
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 7000, "v": -27.288591, "e": [0.47, 0, 0.745, 0.715]}, {"t": 9000, "v": 0}]
            }
          }
        },
        "e8NZwtkV3qQ8": {
          "transform": {
            "data": {
              "o": {"x": 94.3824, "y": 180.971504, "type": "corner"},
              "t": {"x": -94.3824, "y": -180.971504}
            }, "keys": {"r": [{"t": 0, "v": 0, "e": [0.47, 0, 0.745, 0.715]}, {"t": 9000, "v": 360}]}
          }
        },
        "e8NZwtkV3qQ12": {
          "transform": {
            "data": {"a": 1, "t": {"x": -10.929264, "y": -62.402804}}, "keys": {
              "o": [{
                "t": 0,
                "v": {
                  "x": 36.380963,
                  "y": 128.786137,
                  "type": "cusp",
                  "end": {"x": 36.380963, "y": 128.786137},
                  "a": 0
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 1000,
                "v": {
                  "x": 12.202719,
                  "y": 76.555306,
                  "type": "cusp",
                  "start": {"x": 25.26441, "y": 87.682756},
                  "end": {"x": -0.858972, "y": 65.427856}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 2000,
                "v": {
                  "x": -40.93267,
                  "y": 60.658867,
                  "type": "cusp",
                  "start": {"x": -23.164446, "y": 58.173597},
                  "end": {"x": -58.700893, "y": 63.144137}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 3000,
                "v": {
                  "x": -94.984186,
                  "y": 95.200104,
                  "type": "cusp",
                  "start": {"x": -84.450091, "y": 79.297932},
                  "end": {"x": -105.518281, "y": 111.102276}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 4000,
                "v": {
                  "x": -105.519651,
                  "y": 157.332596,
                  "type": "cusp",
                  "start": {"x": -110.514797, "y": 141.230973},
                  "end": {"x": -100.524506, "y": 173.43422}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 5000,
                "v": {
                  "x": -71.164874,
                  "y": 192.791016,
                  "type": "cusp",
                  "start": {"x": -83.794746, "y": 184.775869},
                  "end": {"x": -58.535003, "y": 200.806163}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 6000,
                "v": {
                  "x": -23.52625,
                  "y": 209.096001,
                  "type": "cusp",
                  "start": {"x": -38.948508, "y": 211.933263},
                  "end": {"x": -8.103993, "y": 206.258738}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 7000,
                "v": {
                  "x": 4.68343,
                  "y": 180.971525,
                  "type": "cusp",
                  "start": {"x": -6.197803, "y": 185.938419},
                  "end": {"x": 15.564662, "y": 176.004629}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 8000,
                "v": {
                  "x": 39.684412,
                  "y": 169.005595,
                  "type": "cusp",
                  "start": {"x": 33.850536, "y": 177.806649},
                  "end": {"x": 45.518288, "y": 160.204542}
                },
                "e": [0.47, 0, 0.745, 0.715]
              }, {
                "t": 9000,
                "v": {"x": 36.380963, "y": 128.786137, "type": "corner", "a": -106.21586474040822},
                "e": [0.47, 0, 0.745, 0.715]
              }],
              "r": [{"t": 0, "v": 0, "e": [0, 0, 0.58, 1]}, {"t": 4000, "v": 2160}, {
                "t": 9000,
                "v": 0,
                "e": [0, 0, 0.58, 1]
              }]
            }
          }
        },
        "e8NZwtkV3qQ16": {
          "transform": {
            "data": {
              "o": {"x": 94.7888, "y": 126.866001, "type": "corner"},
              "t": {"x": -94.7888, "y": -126.865999}
            }, "keys": {"r": [{"t": 0, "v": 0}, {"t": 4500, "v": -8.833156}, {"t": 9000, "v": 0}]}
          }
        },
        "e8NZwtkV3qQ21": {
          "transform": {
            "data": {
              "o": {"x": 94.7888, "y": 126.866002, "type": "corner"},
              "t": {"x": -94.7888, "y": -126.866002}
            },
            "keys": {
              "r": [{"t": 0, "v": 0}, {"t": 3000, "v": 4.161929}, {"t": 6000, "v": -7.963338}, {
                "t": 9000,
                "v": 0
              }]
            }
          }
        },
        "e8NZwtkV3qQ25": {
          "transform": {
            "data": {
              "o": {"x": 94.668586, "y": 127.059, "type": "corner"},
              "t": {"x": -94.668586, "y": -127.058997}
            }, "keys": {"r": [{"t": 0, "v": 0}, {"t": 4500, "v": 7.689171}, {"t": 9000, "v": 0}]}
          }
        },
        "e8NZwtkV3qQ29": {
          "transform": {
            "data": {
              "o": {"x": 94.92505, "y": 126.866007, "type": "corner"},
              "t": {"x": -94.92505, "y": -126.866006}
            },
            "keys": {
              "r": [{"t": 0, "v": 0}, {"t": 3000, "v": 7.273105}, {"t": 6000, "v": -6.233038}, {
                "t": 9000,
                "v": 0
              }]
            }
          }
        },
        "e8NZwtkV3qQ33": {
          "transform": {
            "data": {
              "o": {"x": 94.92505, "y": 126.725, "type": "corner"},
              "t": {"x": -94.92505, "y": -126.724998}
            },
            "keys": {
              "r": [{"t": 0, "v": 0}, {"t": 2300, "v": -7.724896}, {"t": 4700, "v": 11.058627}, {
                "t": 9000,
                "v": 0
              }]
            }
          }
        },
        "e8NZwtkV3qQ37": {
          "transform": {
            "data": {
              "o": {"x": 95.345321, "y": 127.059, "type": "corner"},
              "t": {"x": -95.345321, "y": -127.058986}
            },
            "keys": {
              "r": [{"t": 0, "v": 0}, {"t": 3000, "v": 10.135673}, {"t": 6000, "v": -6.122381}, {
                "t": 9000,
                "v": 0
              }]
            }
          }
        },
        "e8NZwtkV3qQ41": {
          "transform": {
            "data": {
              "o": {"x": 94.7888, "y": 126.725002, "type": "corner"},
              "t": {"x": -94.7888, "y": -126.725002}
            },
            "keys": {
              "r": [{"t": 0, "v": 0}, {"t": 2000, "v": 12.532755}, {"t": 5000, "v": -12.307879}, {
                "t": 9000,
                "v": 0
              }]
            }
          }
        },
        "e8NZwtkV3qQ45": {
          "transform": {
            "data": {
              "o": {"x": 94.7888, "y": 127.059014, "type": "corner"},
              "t": {"x": -94.7888, "y": -127.059013}
            },
            "keys": {
              "r": [{"t": 0, "v": 0}, {"t": 2500, "v": 12.792415}, {"t": 5400, "v": -23.01221}, {
                "t": 9000,
                "v": 0
              }]
            }
          }
        },
        "e8NZwtkV3qQ49": {
          "transform": {
            "data": {
              "o": {"x": 94.381561, "y": 127.537911, "type": "corner"},
              "t": {"x": -94.381561, "y": -127.53791}
            },
            "keys": {
              "r": [{"t": 0, "v": 0}, {"t": 2700, "v": -18.721704}, {"t": 5700, "v": 18.826771}, {
                "t": 9000,
                "v": 0
              }]
            }
          }
        },
        "e8NZwtkV3qQ53": {
          "transform": {
            "data": {
              "o": {"x": 94.625726, "y": 127.059014, "type": "corner"},
              "t": {"x": -94.625726, "y": -127.059014}
            },
            "keys": {
              "r": [{"t": 0, "v": 0}, {"t": 2400, "v": -15.37116}, {"t": 5500, "v": 12.860337}, {
                "t": 9000,
                "v": 0
              }]
            }
          }
        },
        "e8NZwtkV3qQ58": {
          "transform": {
            "data": {
              "o": {"x": 94.668586, "y": 126.724998, "type": "corner"},
              "t": {"x": -94.668586, "y": -126.724998}
            },
            "keys": {
              "r": [{"t": 0, "v": 0, "e": [0.785, 0.135, 0.15, 0.86]}, {
                "t": 4500,
                "v": 360,
                "e": [0.785, 0.135, 0.15, 0.86]
              }, {"t": 9000, "v": 0, "e": [0.785, 0.135, 0.15, 0.86]}]
            }
          }
        },
        "e8NZwtkV3qQ61": {
          "transform": {
            "data": {
              "o": {"x": 69.482802, "y": 114.106469, "type": "corner"},
              "t": {"x": -69.482801, "y": -114.106468}
            },
            "keys": {
              "r": [{"t": 0, "v": 0, "e": [0.42, 0, 0.58, 1]}, {"t": 4500, "v": -180}, {
                "t": 9000,
                "v": 0,
                "e": [0.42, 0, 0.58, 1]
              }]
            }
          }
        },
        "e8NZwtkV3qQ64": {
          "transform": {
            "data": {
              "o": {"x": 85.043121, "y": 98.489735, "type": "corner"},
              "t": {"x": -85.043121, "y": -98.489734}
            },
            "keys": {
              "r": [{"t": 0, "v": 0, "e": [0.445, 0.05, 0.55, 0.95]}, {"t": 4500, "v": 90}, {
                "t": 9000,
                "v": 0,
                "e": [0.445, 0.05, 0.55, 0.95]
              }]
            }
          }
        },
        "e8NZwtkV3qQ67": {
          "transform": {
            "data": {
              "o": {"x": 107.974228, "y": 102.599522, "type": "corner"},
              "t": {"x": -107.974228, "y": -102.599522}
            },
            "keys": {
              "r": [{"t": 0, "v": 0, "e": [0.895, 0.03, 0.685, 0.22]}, {"t": 4500, "v": 90}, {
                "t": 9000,
                "v": 0,
                "e": [0.895, 0.03, 0.685, 0.22]
              }]
            }
          }
        },
        "e8NZwtkV3qQ70": {
          "transform": {
            "data": {
              "o": {"x": 123.5345, "y": 121.503533, "type": "corner"},
              "t": {"x": -123.5345, "y": -121.503532}
            },
            "keys": {
              "r": [{"t": 0, "v": 0, "e": [0.645, 0.045, 0.355, 1]}, {"t": 4500, "v": -60}, {
                "t": 9000,
                "v": 0,
                "e": [0.645, 0.045, 0.355, 1]
              }]
            }
          }
        },
        "e8NZwtkV3qQ73": {
          "transform": {
            "data": {
              "o": {"x": 118.620857, "y": 142.873497, "type": "corner"},
              "t": {"x": -118.620857, "y": -142.873497}
            },
            "keys": {
              "r": [{"t": 0, "v": 0, "e": [0.6, 0.04, 0.98, 0.335]}, {
                "t": 4500,
                "v": 55,
                "e": [0.6, 0.04, 0.98, 0.335]
              }, {"t": 9000, "v": 0}]
            }
          }
        },
        "e8NZwtkV3qQ76": {
          "transform": {
            "data": {
              "o": {"x": 101.422336, "y": 158.490006, "type": "corner"},
              "t": {"x": -101.422336, "y": -158.490005}
            },
            "keys": {
              "r": [{"t": 0, "v": 0, "e": [0.95, 0.05, 0.795, 0.035]}, {
                "t": 4500,
                "v": -77,
                "e": [0.95, 0.05, 0.795, 0.035]
              }, {"t": 9000, "v": 0}]
            }
          }
        },
        "e8NZwtkV3qQ79": {
          "transform": {
            "data": {
              "o": {"x": 80.129322, "y": 151.914528, "type": "corner"},
              "t": {"x": -80.129322, "y": -151.914528}
            },
            "keys": {
              "r": [{"t": 0, "v": 0, "e": [0.42, 0, 1, 1]}, {
                "t": 4500,
                "v": 75,
                "e": [0.42, 0, 1, 1]
              }, {"t": 9000, "v": 0, "e": [0.42, 0, 1, 1]}]
            }
          }
        },
        "e8NZwtkV3qQ82": {
          "transform": {
            "data": {
              "o": {"x": 96.428887, "y": 288.112014, "type": "corner"},
              "t": {"x": -96.428887, "y": -288.112014}
            },
            "keys": {
              "r": [{"t": 0, "v": 0, "e": [0.47, 0, 0.745, 0.715]}, {
                "t": 3000,
                "v": 6.62777,
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 6000, "v": -11.048609, "e": [0.47, 0, 0.745, 0.715]}, {
                "t": 9000,
                "v": 0,
                "e": [0.47, 0, 0.745, 0.715]
              }]
            }
          }
        },
        "e8NZwtkV3qQ83": {
          "d": [{
            "t": 0,
            "v": ["M", 92.058487, 243.602, "C", 89.651195, 222.250741, 71.697763, 212.132897, 54.001093, 205.135897, "C", 36.304423, 198.138897, 4.68343, 195.945, 4.68343, 195.945, "L", 4.68343, 241.973, "C", 5.091604, 263.545791, 29.845774, 284.130537, 50.482425, 285.713402, "L", 96.4076, 288, "C", 96.4076, 288, 94.465779, 264.953259, 92.058487, 243.602, "Z"],
            "e": [0, 0, 0.58, 1]
          }, {
            "t": 2000,
            "v": ["M", 92.058487, 243.602, "C", 89.651195, 222.250741, 71.697763, 212.132897, 54.001093, 205.135897, "C", 36.304423, 198.138897, 4.68343, 195.945, 4.68343, 195.945, "L", 4.68343, 241.973, "C", 5.091604, 263.545791, 29.845774, 284.130537, 50.482425, 285.713402, "L", 96.4076, 288, "C", 96.4076, 288, 94.465779, 264.953259, 92.058487, 243.602, "Z"],
            "e": [0, 0, 0.58, 1]
          }, {
            "t": 4600,
            "v": ["M", 96.4076, 241.973, "C", 87.57861, 221.777001, 72.5793, 202.113844, 47.1591, 202.113844, "C", 47.1591, 202.113844, 4.68343, 195.945, 4.68343, 195.945, "L", 4.68343, 241.973, "C", 4.68343, 267.393, 25.2906, 288, 50.7108, 288, "L", 96.4076, 288, "C", 96.4076, 288, 96.4076, 241.973, 96.4076, 241.973, "Z"],
            "e": [0, 0, 0.58, 1]
          }, {
            "t": 7000,
            "v": ["M", 88.9019, 241.9725, "C", 85.106738, 224.453232, 72.5793, 202.113844, 47.1591, 202.113844, "C", 47.1591, 202.113844, 4.68343, 195.945, 4.68343, 195.945, "L", 10.54872, 241.9725, "C", 10.54872, 267.3925, 25.12528, 286.356, 50.54548, 286.356, "L", 96.4076, 288, "C", 96.4076, 288, 92.697062, 259.491767, 88.9019, 241.9725, "Z"],
            "e": [0, 0, 0.58, 1]
          }, {
            "t": 8300,
            "v": ["M", 92.058487, 243.602, "C", 89.651195, 222.250741, 71.697763, 212.132897, 54.001093, 205.135897, "C", 36.304423, 198.138897, 4.68343, 195.945, 4.68343, 195.945, "L", 4.68343, 241.973, "C", 5.091604, 263.545791, 29.845774, 284.130537, 50.482425, 285.713402, "L", 96.4076, 288, "C", 96.4076, 288, 94.465779, 264.953259, 92.058487, 243.602, "Z"],
            "e": [0, 0, 0.58, 1]
          }, {
            "t": 9000,
            "v": ["M", 92.058487, 243.602, "C", 89.651195, 222.250741, 71.697763, 212.132897, 54.001093, 205.135897, "C", 36.304423, 198.138897, 4.68343, 195.945, 4.68343, 195.945, "L", 4.68343, 241.973, "C", 5.091604, 263.545791, 29.845774, 284.130537, 50.482425, 285.713402, "L", 96.4076, 288, "C", 96.4076, 288, 94.465779, 264.953259, 92.058487, 243.602, "Z"]
          }]
        },
        "e8NZwtkV3qQ85": {
          "transform": {
            "data": {
              "o": {"x": 101.321, "y": 288, "type": "corner"},
              "t": {"x": -101.320998, "y": -288}
            },
            "keys": {
              "r": [{"t": 0, "v": 0, "e": [0.47, 0, 0.745, 0.715]}, {
                "t": 3000,
                "v": -7.812958,
                "e": [0.47, 0, 0.745, 0.715]
              }, {"t": 6000, "v": 11.58484, "e": [0.47, 0, 0.745, 0.715]}, {"t": 9000, "v": 0}]
            }
          }
        },
        "e8NZwtkV3qQ86": {
          "d": [{
            "t": 0,
            "v": ["M", 146.364, 265.397, "C", 146.364, 277.88, 136.245, 288, 123.762, 288, "L", 101.321, 288, "L", 101.321, 265.397, "C", 101.321, 252.914, 111.441, 242.795, 123.924, 242.795, "L", 146.364, 242.795, "L", 146.364, 265.397, "Z"]
          }, {
            "t": 2000,
            "v": ["M", 146.364, 265.397, "C", 144.117671, 274.535489, 141.548683, 281.671565, 123.762, 288, "L", 101.321, 288, "L", 101.321, 265.397, "C", 105.297417, 253.698147, 114.431594, 243.707641, 123.924, 242.795, "L", 145.544886, 243.422198, "L", 146.364, 265.397, "Z"]
          }, {
            "t": 4600,
            "v": ["M", 146.364, 265.397, "C", 146.364, 277.88, 136.245, 288, 123.762, 288, "L", 101.321, 288, "L", 101.321, 265.397, "C", 101.321, 252.914, 111.441, 242.795, 123.924, 242.795, "L", 146.364, 242.795, "L", 146.364, 265.397, "Z"]
          }, {
            "t": 6600,
            "v": ["M", 146.364, 265.397, "C", 144.117671, 274.535489, 141.548683, 281.671565, 123.762, 288, "L", 101.321, 288, "L", 101.321, 265.397, "C", 105.297417, 253.698147, 114.431594, 243.707641, 123.924, 242.795, "L", 145.544886, 243.422198, "L", 146.364, 265.397, "Z"]
          }, {
            "t": 9000,
            "v": ["M", 146.364, 265.397, "C", 146.364, 277.88, 136.245, 288, 123.762, 288, "L", 101.321, 288, "L", 101.321, 265.397, "C", 101.321, 252.914, 111.441, 242.795, 123.924, 242.795, "L", 146.364, 242.795, "L", 146.364, 265.397, "Z"]
          }]
        },
        "e8NZwtkV3qQ88": {
          "transform": {
            "data": {
              "o": {"x": 128.347, "y": 222.247001, "type": "corner"},
              "t": {"x": -128.347, "y": -222.247}
            },
            "keys": {
              "r": [{"t": 0, "v": 0}, {"t": 3000, "v": 23.613813}, {"t": 6000, "v": -30.266249}, {
                "t": 9000,
                "v": 0
              }]
            }
          }
        }
      },
      "s": "MDRA1ZDhmMzY3OXDg5ODY3NTg4KN2Q4MzgyMzYQ0ZTRkNDQ0NDUQ0NDAzNjc4NQ2Q4Njc5Nzc4UODdkODM4MjMN2NGU0NTQwMzNY3ZEc4ODc5ONDY3NTg4N2Q4BMzgyODczNjRJlNDQ0MDM2N2PE3ZFk4ME84MIDM2NGVQNDU0UMDM2NzU4MDgN4Nzk4NjgyNzNU4OFU3OTM2RIzRlN2FONzU4QMDg3Nzk0MDMB2ODc4NDc5NzWk3ODM2NGU0NBTQwRzM2N2FDVODQ4NzM2NGUQ0NTQ0NDQ5MQS|"
    }], "options": "MDUAxMDgyMjk3YTTdiNjg3OTdiYMjk0MTI5NzNNONzY2ODZiMjDk4NA|"
  }, 'https://cdn.svgator.com/ply/', '__SVGATOR_PLAYER__', '2024-09-05', window, document, 'script', 'http://www.w3.org/2000/svg', 'http://www.w3.org/1999/xlink')
}
