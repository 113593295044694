export const environment = {
  production: true,
  apiHost: 'https://api-dev.develop.our-trace.com',
  spaHost: 'https://dev-app.develop.our-trace.com',
  spaVersion: '6441',
  cognitoCookieDomain: '.develop.our-trace.com',
  cognitoCookieSecure: true,
  cognitoRegion: 'ap-southeast-2',
  cognitoDomain: 'auth.dev-app.develop.our-trace.com',
  cognitoUserPoolId: 'ap-southeast-2_e9b3pJ9mV',
  cognitoUserPoolWebClientId: '9pul7qb83ptpju36o5ub8ou6j',
  cognitoRedirectSignIn: 'https://dev-app.develop.our-trace.com/external-login',
  cognitoRedirectSignOut: 'https://dev-app.develop.our-trace.com/external-logout',
  googleAnalyticsTrackId: 'G-N82E3DCHVL',
  googleMapAPIKey: 'AIzaSyDeclaXCI0cEIqaJVGEhZT8bmLdGb9N5BQ',
  chargeBeeSite: 'our-trace-test',
  chargeBeeKey: 'test_Hv8ccdZQlub0N3wtWcdcdMl2Nqljib2SnX2',
  chargeBeeScriptUrl: 'https://js.chargebee.com/v2/chargebee.js',
  publicContentDomain: 'https://dev-public.develop.our-trace.com',
  reportDocumentLink: '',
  bookACallLink: '',
  traceForBusinessLink: '',
  traceGetAQuoteLink: 'https://www.our-trace.com/business/get-a-quote',
  carbonNeutralLanguageBlogLink: 'https://www.our-trace.com/blog/carbon-neutral-language',
  announcePressReleaseArticleId: 'c00bf885-7e6a-410d-9695-fa7e25403b24',
  announceMileStoneArticleId: '1df4dd5d-4b39-40d9-8808-49b658c497f9',
  homeArticleIds:
    'c00bf885-7e6a-410d-9695-fa7e25403b24,7c2c46b1-ee75-4596-9e1f-34935fe34845,1df4dd5d-4b39-40d9-8808-49b658c497f9,0cd94c12-0efc-46eb-8854-e008301fde8f',
  websocketUrl: 'wss://websocket-dev.develop.our-trace.com',
  hubSpotScriptUrl: 'https://js.hsforms.net/forms/embed/v2.js',
  hubSpotCreateFormRegion: 'na1',
  hubSpotReferralFormPortalId: '24047067',
  hubSpotReferralFormId: '576f3654-ad14-4653-8e86-0e4d57747d46',
  mfaAppName: 'Trace dev',
  handsontableLicenseKey: '19a91-8043d-1c8a0-9e92c-8e651',
  microsoftClientId: '2678e590-daba-46be-b4d8-5304b0741989',
  ourProjectsLink: 'https://www.our-trace.com/our-projects',
  googleMapId: '69021e4fb0fbc230'
};
